<template>
  <p v-html="detail"></p>
</template>

<script>
import { messageDetail } from "@/api/messages";
export default {
  data() {
    return {
      detail: ""
    };
  },
  props: {
    detailId: {
      type: String,
      default() {
        return null;
      }
    }
  },
  created() {
    this.initDetail();
  },
  methods: {
    initDetail() {
      messageDetail(this.detailId).then((result) => {
        this.detail = result.data;
      });
    }
  }
};
</script>

<style>
</style>