<template>
  <div class="layoutContent">
    <home-title
      :description="doc"
      :showpic="
        getConfigVal(curPageConfigAndDefaultPageConfig, 'showPic', 'vIf')
      "
    />
    <div class="contentParty">
      <div
        v-if="messageCount > 0"
        class="d-flex align-items-center justify-content-md-start mb-2 flex-md-row-reverse"
        style="border-bottom: 1px solid #e7eaee; padding-bottom: 20px"
      >
        <k-form-checkbox
          v-if="messageCount > 0"
          switch
          v-model="showUnread"
          size="lg"
          @change="showUnreadChange"
          class="messageSwitch"
        >
        </k-form-checkbox>
        <div style="padding-top: 0.3125rem" class="mr-2">
          {{ $g("onlyShowUnread") }}
        </div>
      </div>
      <div v-html="noDataDoc" v-if="noDataDoc && messageCount <= 0"></div>
      <konn-grid
        ref="selectableTable"
        :data-source="dataSource"
        :get-condition="getCondition"
        :class="{ 'table-show-search-area': true }"
        :grid-dort-desc="sortDesc"
        sort-icon-left
        :fields="fields"
        :hasAction="false"
        :hasCheckBox="false"
        class="messageTable cardBody"
        :rowClass="rowClass"
        :pageClass="pageClass"
        :hasPager="hasPager"
        @total-changed="totalChanged"
      >
        <template #cell(subject)="row">
          <div class="messageList">
            <div class="titleSubject">
              <div class="electionTitle">{{ row.item.electionTitle }}</div>
              <a
                class="subject"
                href="javascript:void(0)"
                @click="subjectClick(row.item)"
              >
                {{ row.item.subject }}
              </a>
            </div>
            <div class="sendTimeTypeRead">
              <div class="sendTimeType">
                <div class="sendTime">{{ setDate(row.item.sendTime) }}</div>
                <div class="messageType">{{ row.item.messageType }}</div>
              </div>
              <div class="readIcon">
                <b-icon
                  icon="check-circle-fill"
                  :class="row.item.isRead ? 'read' : 'noRead'"
                  @click="onSetMessage(row.item)"
                  v-b-tooltip="{
                    placement: 'bottomleft',
                    trigger: 'hover',
                    title: row.item.isRead
                      ? $g('markAsUnRead')
                      : $g('markAsRead'),
                    boundary: 'window',
                  }"
                ></b-icon>
              </div>
            </div>
          </div>
        </template>
      </konn-grid>
    </div>
    <k-popwindow
      id="messagesDetail"
      :checkSelected="false"
      :title="$g('popwindow.message')"
      :hideOk="true"
      :cancelTitle="$g('popwindow.close')"
    >
      <messagesPopWindowDetail :detailId="detailId"></messagesPopWindowDetail>
    </k-popwindow>
  </div>
</template>

<script>
import { getPageDesc } from "@/api/login";
import { messagePage, messageSet, messageCount } from "@/api/messages";
import {
  repResult,
  storeage,
  getCurPageConfigAndDefaultPageConfigbyRoutePath,
  getConfigVal,
} from "fe-infrastractures";
import Moment from "moment-timezone";
import HomeTitle from "@/views/components/homeTitle";
import messagesPopWindowDetail from "./messages-popwindow-detail.vue";
export default {
  components: { HomeTitle, messagesPopWindowDetail },
  data() {
    let curPageConfigAndDefaultPageConfig =
      getCurPageConfigAndDefaultPageConfigbyRoutePath(
        "workerPortal",
        this.$route.path
      );
    return {
      curPageConfigAndDefaultPageConfig,
      getConfigVal,
      pageClass: [],
      showUnread: true,
      items: [],
      sortDesc: true,
      fields: [{ key: "subject", table: "subject" }],
      doc: "",
      noDataDoc: "",
      timeZone: storeage.getTimeZone(),
      systemGeneralInfo: storeage.getSystemGeneralInfo(),
      hasPager: false,
      detailId: "",
      lastReadItem: null,
      messageCount: 0,
    };
  },
  computed: {
    dataSource() {
      return messagePage;
    },
  },
  created() {
    this.initMessageCount();
  },
  methods: {
    GridRequestChange() {
      if (this.messageCount <= 0) {
        getPageDesc("Message-NoData").then((result) => {
          this.noDataDoc = result.data.description;
        });
      } else {
        this.noDataDoc = "";
      }
      this.initPageDesc("Message");
    },
    initMessageCount() {
      messageCount({ showUnread: false }).then((result) => {
        this.messageCount = result.data;
        this.GridRequestChange();
      });
    },
    subjectClick(item) {
      this.detailId = item.id;
      if (!item.isRead) {
        let data = {
          id: item.id,
          ShowUnread: true,
        };
        messageSet(data).then((result) => {
          if (result.data.status == repResult.success) {
            item.isRead = true;
            this.$refs.selectableTable.pageChange(1);
            this.lastReadItem = item;
            this.changeMessageCount(item);
          }
        });
      }
      this.$bvModal.show("messagesDetail");
    },
    totalChanged(total) {
      if (total > 0) {
        this.hasPager = true;
      } else if (!this.showUnread) {
        this.hasPager = false;
      }
    },
    setDate(d) {
      let strTemplate = this.systemGeneralInfo.dateFormat
        ? `${this.systemGeneralInfo.dateFormat.toUpperCase()} hh:mm:ss A`
        : "MM/DD/YYYY hh:mm:ss A";
      let data = Moment(d, strTemplate);
      //let utc = (0 - new Date().getTimezoneOffset()) / 60;
      let utc = 0;
      data.hours(data.hours() + utc);
      return data.format(strTemplate);
    },
    initPageDesc(PageDescQuery) {
      getPageDesc(PageDescQuery)
        .then((result) => {
          this.doc = result.data.description;
        })
        .catch((err) => {
          throw err;
        });
    },
    getCondition() {
      return {
        showUnread: this.showUnread,
      };
    },
    showUnreadChange() {
      this.$refs.selectableTable.refreshSetPage1();
    },
    onSetMessage(item) {
      let data = {
        id: item.id,
        ShowUnread: !item.isRead,
      };
      messageSet(data)
        .then((result) => {
          if (result.data.status == repResult.success) {
            this.$refs.selectableTable.pageChange(1);
            item.isRead = !item.isRead;
            this.changeMessageCount(item);
          }
        })
        .catch((err) => {
          throw err;
        });
    },
    changeMessageCount(item) {
      let messageCount = this.$store.state.app.portalMessageCount || 0;
      if (messageCount > 0) {
        messageCount = item.isRead ? messageCount - 1 : messageCount + 1;
        this.$store.dispatch("app/setPortalMessageCount", messageCount);
      }
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (!item.isRead) return "rowUnread";
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .messageTable {
    table {
      border-bottom: 0;
    }
    thead {
      display: none;
    }
    tr {
      background: none !important;
    }

    td {
      vertical-align: middle;
      background: none !important;
      border: 0;
    }
    .noRead {
      color: #7f7f7f !important;
    }
    .read {
      color: var(--checkboxAndRadio) !important;
    }
    .messageList {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 20px;
      background: #ffffff;
      border-radius: 8px;
      .titleSubject {
        font-size: 1.1rem;
        .electionTitle {
          color: var(--colorFontDefault);
        }
        .subject {
          font-size: 1.3rem;
          font-weight: bold;
          color: var(--featuredFont);
        }
      }
      .sendTimeTypeRead {
        display: flex;
        align-items: center;
        .sendTimeType {
          margin-right: 50px;
          text-align: right;
          font-size: 1.1rem;
          .sendTime {
            font-weight: bold;
            font-size: 1.3rem;
          }
          .messageType {
            color: var(--colorFontDefault);
          }
        }
        .readIcon {
          width: 100px;
          text-align: center;
        }
      }
    }
    .rowUnread {
      .messageList {
        background: #fae99b;
      }
    }
    @media (max-width: 767px) {
      .messageList {
        flex-direction: column-reverse;
        align-items: initial;
        padding: 0;
        .titleSubject {
          padding: 0 10px 10px;
          font-size: 1rem;
          .subject {
            font-size: 1.2rem;
          }
        }
        .sendTimeTypeRead {
          justify-content: space-between;
          padding: 10px 10px 0;
          .sendTimeType {
            margin-right: 10px;
            text-align: left;
            font-size: 1rem;
            .sendTime {
              font-size: 1.2rem;
            }
          }
          .readIcon {
            width: auto;
            padding-right: 10px;
          }
        }
      }
      .table-pagination > div {
        margin-bottom: 5px;
      }
    }
  }
}
</style>
